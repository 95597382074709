import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Autocomplete,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Chip,
} from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import { url } from "../../../Helpers/utils/Constant";
import { showToast } from "../../../Toaster/Toaster";
import { ICompanyProposer } from "../../../rest/types";
import { IMemberOrganizationProposer } from "../../../Types/UpdateCompanyInfoTypes";
import { useMutation, useQueryClient } from "react-query";
import { UpdateCompanyProposerMutation } from "../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";

interface IProps {
  memberId: number;
  companyProposer: ICompanyProposer;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateCompanyProposer = ({
  memberId,
  companyProposer,
  setToggle,
}: IProps) => {
  const { handleSubmit } = useForm();
  const [memberOrganizationProposer, setMemberOrganizationProposer] = useState<
    IMemberOrganizationProposer[]
  >([]);
  const [proposerInput, setProposerInput] = useState<any>({
    proposer_request_to: "",
    proposer_seal_signature: "",
    proposer_signature: "",
  });
  const queryClient = useQueryClient();
  const [companyName, seTCompanyName] = useState<string>("");
  useEffect(() => {
    axios
      .get(
        `${url}/api/atab/user-member/company-name/get-all/status-or-all/active?company_name=${encodeURIComponent(
          companyName
        )}`
      )
      .then(function (res) {
        if (res.data.success) {
          setMemberOrganizationProposer(res.data.data);
        }
      });
  }, [companyName]);

  const proposerOption = memberOrganizationProposer.map((option: any) => ({
    id: option.user_member_id,
    label: option.user_member_company_name,
  }));

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyProposerMutation(data);
    },
    onSuccess: () => {
      showToast("successfully updated", "success");
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, "error");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_PROPOSER_UPDATE],
      });
    },
  });

  const onSubmit = async (_inputData: any) => {
    // inputData.proposer_request_id = companyProposer.proposer_request_id;
    // inputData.proposer_request_from = memberId;

    const formData = new FormData();
    formData.append(
      "proposer_request_id",
      companyProposer.proposer_request_id.toString()
    );
    formData.append("proposer_request_from", memberId.toString());

    for (const key in proposerInput) {
      if (proposerInput[key]) {
        formData.append(key, proposerInput[key]);
      }
    }

    // console.table(Object.fromEntries(formData));
    mutation.mutate(formData);
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      p={2}
      mb={1}
      mt={3}
      sx={{ position: "relative" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box>
            <Autocomplete
              fullWidth
              disablePortal
              defaultValue={{
                id: companyProposer?.proposer_request_to,
                label: companyProposer?.proposer_request_to_company_name,
              }}
              onChange={(e: any, newValue: any) => {
                setProposerInput({
                  ...proposerInput,
                  proposer_request_to: String(newValue?.id),
                });
              }}
              isOptionEqualToValue={(proposerOption, value) =>
                proposerOption.id === value.id
              }
              loading={true}
              id="controllable-states-demo"
              options={proposerOption}
              onInputChange={(_event, newInputValue) => {
                seTCompanyName(newInputValue);
              }}
              // sx={{ width: "300px" }}
              getOptionLabel={(option) => option.label}
              renderTags={(value: readonly any[], getTagProps) =>
                value.map((option: any, index: number) => (
                  <Chip
                    variant="filled"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Select proposer name"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="outlined-basic"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                accept: "image/jpeg,image/png,image/jpg",
              },
            }}
            variant="outlined"
            type="file"
            fullWidth
            label="proposer seal and signature ( max size 5mb )"
            onChange={(e: any) => {
              setProposerInput({
                ...proposerInput,
                proposer_seal_signature: e.target.files[0],
              });
            }}
          />
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <TextField
            id="outlined-basic"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            type="file"
            fullWidth
            label="proposer signature ( max size 5mb )"
            onChange={(e: any) => {
              setProposerInput({
                ...proposerInput,
                proposer_signature: e.target.files[0],
              });
            }}
          />
        </Grid> */}
      </Grid>
      <Box sx={{ position: "absolute", top: 0, right: 0 }}>
        <Tooltip title="Update" sx={{ mr: 2 }}>
          <IconButton onClick={() => setToggle((prv) => !prv)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {mutation.isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Loading..
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyProposer;
