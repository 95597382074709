// export const url: string = "http://192.168.0.237:5000"; //moon
export const localURL: string = "http://192.168.0.59:5000"; //srabon
// export const url: string = "http://192.168.0.191:5000"; //jasim
const liveURL: string = "https://server.atab.services";
export const url: string =
  process.env.NODE_ENV === "development" ? localURL : liveURL;
export const imgUrl: string =
  "https://m360ict.s3.ap-south-1.amazonaws.com/uploads";

//======== Auth context helper ========//
export const SET_SIGNUP_DATA = "SET_SIGNUP_DATA";
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
export const AUTH_USER_FAILED = "AUTH_USER_FAILED";
export const UPDATE_AUTH_USER_INFO = "UPDATE_AUTH_USER_INFO";
// notification
export const NEW_NOTIFICATION = "NEW_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";

// message
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_NEW_MESSAGE = "SET_NEW_MESSAGE";

export const debounceTime = 2000;
